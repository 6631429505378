import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { compose } from 'redux'
import { TextField } from 'redux-form-material-ui'

import { Button } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import moment from 'moment'

let UserForm = ({ initialValues, handleSubmit, theme }) => {
  let isNew = !initialValues || !initialValues._id

  return (
    <form onSubmit={ handleSubmit }>
      <Field
        name="email"
        label="Email Address"
        component={ TextField }
        className="formGroup"
      />
      <Field
        name="name.given"
        label="First Name"
        component={TextField}
        className="formGroup"
      />
      <Field
        name="name.family"
        label="Last Name"
        component={TextField}
        className="formGroup"
      />
      {
        isNew &&
        <Field
          name="pass"
          label="Password"
          component={TextField}
          type="password"
          autoComplete="no"
          className="formGroup"
        />
      }
      <Field
        name="dob"
        label="Date of Birth"
        component={TextField}
        type="date"
        format={(value) => (moment(value).utc().format('YYYY-MM-DD'))}
        className="formGroup"
      />

      <Button type="submit" variant="contained" className="button" style={theme.button.primary}>
        { isNew ? 'Register User' : 'Update Profile' }
      </Button>

      <footer>
        <a href={process.env.REACT_APP_MANAGE_URL} style={{ color: theme.palette.primary.main }}>Go to Manage</a>
      </footer>

    </form>
  )
}

export default compose(withStyles(null, { withTheme: true }), reduxForm({ form: 'user' }))(UserForm)
