import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Switch, Route, Redirect } from 'react-router-dom'
import classNames from 'classnames'

// Components
import UserLogin from './UserLogin'
// import UserRegister from './UserRegister'
import UserForgotPassword from './UserForgotPassword'
import UserResetPassword from './UserResetPassword'
import UserProfile from './UserProfile'
import SnackBar from './Snackbar'
import Loading from './Loading'

import { authenticate, logout } from 'common/actions'
import { setDestination } from '../actions'

import theme from 'common/client/theme'
import logo from 'common/images/logo-placeholder.png'

const mapStateToProps = ({ status, currentUser }) => ({ status, currentUser })
const mapDispatchToProps = { authenticate, logout, setDestination }

class App extends Component {
  componentDidMount () {
    this.props.authenticate()
    // get the destination from the URL query, and store that in the store
    let params = new URL(window.location).searchParams
    let destination = params.get('destination')
    if (destination) {
      // @todo validate this against a list of safe destinations
      this.props.setDestination(destination)
    }
  }

  logout = () => {
    this.props.logout()
  }

  render () {
    if (this.props.status.isLoading) {
      return (
        <div id="app" style={{ backgroundColor: theme.palette.primary.main }}>
          <Loading dark={true} />
        </div>
      )
    }

    return (
      <div id="app" style={{ backgroundColor: theme.palette.primary.main }}>
        <div id="page">
          <img className={classNames('brand', { 'loggedIn': this.props.currentUser })} alt="logo" src={logo} width="184" height="62" />
          <Switch>
            <Route exact path="/" render={() => (<Redirect to={this.props.currentUser ? '/user/profile' : '/user/login' } />)} />
            <Route path="/user/login" component={UserLogin}/>
            {/* <Route path="/user/register" component={UserRegister}/> */}
            <Route path="/user/password" component={UserForgotPassword}/>
            <Route path="/user/reset/:token" component={UserResetPassword} />
            <Route path="/user/profile" component={UserProfile} />
          </Switch>
        </div>
        <SnackBar />
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
